import React from 'react'
import {useField} from 'formik';
import './styles.css';
import {MIN_CODAREA_ASESORAMIENTO, MIN_NUMERO_ASESORAMIENTO, MIN_NUMTEL_ASESORAMIENTO} from "../../../../constants";

const Number = ({widthInput,mright,...props}) => {
    const [field,meta] = useField({...props,type:"number"})
    return (
        <div className = "containerInput">
             <input className = "inputNumber input" type="number" {...field} {...props} style = {{width :`${widthInput}px`, marginRight:`${mright}px`}}/>
            {field.name === 'numero' && meta.touched && meta.value.toString().length !== 8 ? <p className = "errors">{MIN_NUMTEL_ASESORAMIENTO}</p>:null}
            {field.name === 'codigo_nacional' && meta.touched && (meta.value.toString().length < 2 || meta.value.toString().length > 4) ? <p className = "errors">{MIN_CODAREA_ASESORAMIENTO}</p>:null}
            {field.name === 'docNum' && meta.touched && (meta.value.toString().length < 8 || meta.value.toString().length > 9) ? <p className = "errors">{MIN_NUMERO_ASESORAMIENTO}</p>:null}
        </div>
    )
}

export default Number
